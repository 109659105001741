import React from 'react';
import {
  Avatar,
  Button,
  Link as ChakraLink,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { FaHome, FaKey } from 'react-icons/fa';
import { store } from '@src/store';

export const Sidebar = ({ children }) => {
  const { name } = store((state) => state);
  const navigate = useNavigate();

  return (
    <Flex
      flexDir={['column', 'column', 'row']}
      h={[null, null, '100vh']}
      maxW="2000px"
      overflow="hidden"
    >
      <Flex
        alignItems="center"
        backgroundColor="#ffffff"
        color="#1d1d1d"
        flexDir="column"
        w={['100%', '100%', '10%', '15%', '15%']}
      >
        <Flex
          flexDir="column"
          h={[null, null, '100vh']}
          justifyContent="space-between"
        >
          <Flex as="nav" flexDir="column">
            <Heading
              alignSelf="center"
              fontSize={['4xl', '4xl', '2xl', '3xl', '4xl']}
              letterSpacing="tight"
              mb={[25, 50, 100]}
              mt={50}
            >
              Affix
            </Heading>

            <Flex
              align={['center', 'center', 'center', 'flex-start', 'flex-start']}
              flexDir={['row', 'row', 'column', 'column', 'column']}
              justifyContent="center"
              wrap={['wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap']}
            >
              <Flex
                alignSelf="center"
                mr={[2, 6, 0, 0, 0]}
                style={{ marginBottom: '1.5rem' }}
              >
                <ChakraLink
                  as={ReactRouterLink}
                  display={['none', 'none', 'flex', 'flex', 'flex']}
                  style={{ marginRight: '0.5rem' }}
                  to="/home"
                >
                  <FaHome color="inherit" size={20} />
                </ChakraLink>

                <ChakraLink
                  _hover={{ textDecor: 'none' }}
                  as={ReactRouterLink}
                  display={['flex', 'flex', 'none', 'flex', 'flex']}
                  to="/home"
                >
                  <Text className="active">Account</Text>
                </ChakraLink>
              </Flex>

              <Flex
                alignSelf="center"
                mr={[2, 6, 0, 0, 0]}
                style={{ marginBottom: '1.5rem' }}
              >
                <ChakraLink
                  as={ReactRouterLink}
                  display={['none', 'none', 'flex', 'flex', 'flex']}
                  style={{ marginRight: '0.5rem' }}
                  to="/tokens"
                >
                  <FaKey color="inherit" size={20} />
                </ChakraLink>

                <ChakraLink
                  _hover={{ textDecor: 'none' }}
                  as={ReactRouterLink}
                  display={['flex', 'flex', 'none', 'flex', 'flex']}
                  to="/tokens"
                >
                  <Text className="active">Tokens</Text>
                </ChakraLink>
              </Flex>
            </Flex>
          </Flex>

          <Flex alignItems="center" flexDir="column" mb={10} mt={5}>
            <Avatar my={2} name={name} />
            <Text textAlign="center">{name}</Text>
          </Flex>

          <Flex alignItems="center" flexDir="column" mb={10} mt={5}>
            <Text
              as={Button}
              onClick={() => {
                store.setState({
                  clientId: '',
                  clientSecret: '',
                  name: '',
                });

                navigate('/');
              }}
              textAlign="center"
            >
              Logout
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        backgroundColor="#f5f5f5"
        flexDir="column"
        minH="100vh"
        overflow="auto"
        p="3%"
        w={['100%', '100%', '60%', '60%', '55%']}
      >
        {children}
      </Flex>
    </Flex>
  );
};
