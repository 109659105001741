import { Route, Routes } from 'react-router-dom';

import { Tokens } from '@pages/Tokens';
import { Home } from '@pages/Home';
import { Client } from '@pages/Client';
import { store } from '@src/store';
import { ProtectedRoute } from '@src/lib/utils-tsx';

export const App = () => {
  const { clientId, clientSecret, name } = store((state) => state);

  const isAuthenticated = () =>
    !clientId || !clientSecret || !name ? false : true;

  return (
    <div>
      <Routes>
        {/* @ts-expect-error blahblahblah */}
        <Route element={<Home />} exact path="/" />
        {/* @ts-expect-error blahblahblah */}
        <Route element={<ProtectedRoute isAllowed={isAuthenticated} />}>
          <Route element={<Client />} path="/client" />
          <Route element={<Tokens />} path="/tokens" />
          {/* 404 route */}
          <Route element={<Home />} path="*" />
        </Route>
      </Routes>
    </div>
  );
};
