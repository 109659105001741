import React from 'react';
import {
  Avatar,
  Button, // eslint-disable-line @typescript-eslint/no-unused-vars
  Flex, // eslint-disable-line @typescript-eslint/no-unused-vars
  Heading,
  Input, // eslint-disable-line @typescript-eslint/no-unused-vars
  InputGroup, // eslint-disable-line @typescript-eslint/no-unused-vars
  Stack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@affixapi/api';

import { env } from '@src/lib/utils';
import { MainLayout } from '@src/components/layouts/MainLayout';
import { store } from '@src/store';
import { client } from '@services/management';
import { Loading } from '@src/components/Loading';
import { Error } from '@src/components/Error';

export const Client = () => {
  const { clientId, clientSecret } = store((state) => state);

  const { data, error, isLoading } = useQuery({
    queryFn: async () => client({ clientId, clientSecret }),
    queryKey: ['userData'],
  });

  if (isLoading) return <Loading></Loading>;

  if (error || !data) return <Error error={error}></Error>;

  const xhrScopes = api.consts.scopes.filter((scope) => scope.includes('xhr'));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const officialScopes = api.consts.scopes.filter((scope) =>
    scope.includes('official')
  );

  const envPrefix = env === 'dev' ? 'dev.' : '';

  // https://dev.connect.affixapi.com/?client_id=3FDAEDF9-1DCA4F54-87949F6A-41027643&mode=xhr&scope=/2023-03-01/xhr/company%20/2023-03-01/xhr/identity%20/2023-03-01/xhr/employee%20/2023-03-01/xhr/employees%20/2023-03-01/xhr/timesheets%20/2023-03-01/xhr/time-off-entries%20/2023-03-01/xhr/time-off-balances%20/2023-03-01/xhr/payruns%20/2023-03-01/xhr/payruns/:payrun_id%20/2023-03-01/xhr/work-locations%20/2023-03-01/xhr/groups%20&redirect_uri=https://affixapi.com
  const xhrConnectLink = `https://${envPrefix}connect.affixapi.com/?client_id=${data.client_id}&mode=xhr&scope=${xhrScopes.join(' ')}&redirect_uri=${data.redirect_uris[0]}`;

  return (
    <MainLayout>
      <Stack>
        <Avatar alignSelf="center" name={data.name} size="xl" />

        <Stack spacing="1rem">
          <Heading> {data.name} </Heading>
        </Stack>

        <Stack spacing="1rem">
          <Text>{data.client_id}</Text>
        </Stack>

        <Stack spacing="1rem">
          <Text>Client Secret(s)</Text>
          {data.client_secret.map((clientSecret) => (
            <Text>{clientSecret}</Text>
          ))}
        </Stack>

        <Stack spacing="1rem">
          <Text>Redirect URI(s)</Text>
          {data.redirect_uris.map((redirectUrl) => (
            <Text>
              {'- '}
              <a href={redirectUrl}>{redirectUrl}</a>
            </Text>
          ))}
        </Stack>

        <Stack spacing="1rem">
          <Text>Webhook URI (optional)</Text>
          <Text>{data.webhook_uri ? `- ${data.webhook_uri}` : 'None'}</Text>
        </Stack>

        <Stack spacing="1rem">
          <Text>Connect link</Text>
          <a href={xhrConnectLink}>{xhrConnectLink}</a>
        </Stack>
      </Stack>
    </MainLayout>
  );
};
